body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --heading-8: Poppins;
  --font-roboto: Roboto;
  --font-plus-jakarta-sans: "Plus Jakarta Sans";
  --font-inter: Inter;

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-lg: 18px;
  --font-size-mini: 15px;
  --font-size-smi: 13px;
  --font-size-xs: 12px;
  --font-size-lgi: 19px;
  --font-size-6xl: 25px;
  --font-size-25xl: 44px;
  --font-size-2xs: 11px;
  --font-size-3xs: 10px;
  --font-size-xl: 20px;
  --font-size-mid: 17px;
  --heading-8-size: 16px;
  --font-size-5xl: 24px;
  --font-size-3xl: 22px;
  --font-size-41xl: 60px;
  --font-size-21xl: 40px;
  --font-size-13xl: 32px;
  --font-size-mid-3: 17.3px;
  --font-size-31xl: 50px;

  /* Colors */
  --color-white: #fff;
  --color-darkorange: #f69429;
  --color-mediumspringgreen: #26d367;
  --color-gainsboro-100: #dedede;
  --color-gainsboro-200: #d9d9d9;
  --color-gainsboro-300: rgba(217, 217, 217, 0.58);
  --color-darkslategray-100: #404040;
  --color-darkslategray-200: #313131;
  --color-darkslategray-300: #193d1c;
  --color-darkslategray-400: rgba(49, 49, 49, 0.66);
  --color-chocolate-100: #f26e2a;
  --color-chocolate-200: #f26922;
  --color-chocolate-300: rgba(242, 105, 34, 0.13);
  --color-black: #000;
  --color-gray-100: #969393;
  --color-gray-200: #959292;
  --color-gray-300: #828282;
  --color-gray-400: #818181;
  --color-gray-500: #787878;
  --black: #232536;
  --color-gray-600: #212121;
  --color-gray-700: rgba(0, 0, 0, 0.5);
  --color-gray-800: rgba(255, 255, 255, 0.2);
  --color-gray-900: rgba(0, 0, 0, 0.72);
  --color-gray-1000: rgba(120, 120, 120, 0.61);
  --color-whitesmoke-100: #f7f6f6;
  --color-whitesmoke-200: #f5f5f5;
  --color-whitesmoke-300: #f4f4f4;
  --color-whitesmoke-400: #f3f2f2;
  --color-whitesmoke-500: #ece8e8;
  --color-darkgray-100: #b6adad;
  --color-darkgray-200: #afadad;
  --color-darkgray-300: #9c9999;
  --color-darkgray-400: rgba(176, 176, 176, 0.28);
  --medium-grey: #6d6e76;
  --color-dimgray-100: #616161;
  --color-dimgray-200: #535353;
  --color-dimgray-300: #4c4c4c;
  --color-lightslategray: #90a3bf;
  --color-lightgray: #cacaca;
  --color-coral: #f48247;
  --color-lavender-100: #eaeaff;
  --color-silver: #c6c6c6;

  /* Gaps */
  --gap-5xl: 24px;
  --gap-base: 16px;

  /* Paddings */
  --padding-5xl: 24px;
  --padding-lgi-7: 19.7px;
  --padding-3xs-9: 9.9px;
  --padding-base: 16px;

  /* Border radiuses */
  --br-15xl: 34px;
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-8xs: 5px;
  --br-base-5: 15.5px;
  --br-xs-5: 11.5px;
  --br-base: 16px;
  --br-lgi: 19px;
  --br-6xs: 7px;
  --br-6xl: 25px;
  --br-2xs: 11px;
  --br-5xs: 8px;

  /* Effects */
  --drop-shadow: 5px 10px 50px rgba(17, 17, 43, 0.05);
}
